var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-area shuttle-plan-list"},[_c('hero-bar',[_vm._v(" "+_vm._s(_vm.$t('titles.shuttleRegisterList'))+" "),_c('b-tooltip',{attrs:{"slot":"right","label":_vm.$t('refresh'),"type":"is-dark","position":"is-bottom"},slot:"right"},[_c('b-button',{staticClass:"mr-10",class:{ 'is-loading': _vm.refreshLoading },attrs:{"id":"refresh-button","size":"is-circle","type":"is-link","outlined":"","icon-left":"refresh"},on:{"click":_vm.refresh}})],1),(_vm.checkPermission('shuttle_register'))?_c('b-button',{attrs:{"slot":"right","id":"new-plan","tag":"router-link","to":{ name: 'shuttle_register'},"icon-left":"plus-circle","type":"is-link"},slot:"right"},[_vm._v(" "+_vm._s(_vm.$t('newShuttleRegisterRequest'))+" ")]):_vm._e()],1),_c('section',{staticClass:"section is-main-section"},[_c('card-component',{staticClass:"has-table has-mobile-sort-spaced bg-none"},[(_vm.checkPermission('shuttle_days_list'))?_c('b-tabs',{attrs:{"animated":false,"destroy-on-hide":false,"size":"is-medium","type":"is-boxed"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":"Gün Listesi","icon":"table-large"}},[_c('smart-table',{ref:"daysTable",attrs:{"data-url":"shuttle-days-list","sort":{
              defaultOrder: 'desc',
              field: 'register_date',
              order: 'asc',
            },"filters":{ register_date: [new Date().toJSON().slice(0, 10), new Date().toJSON().slice(0, 10)], with_trashed: 1 },"loading":_vm.refreshLoading},on:{"update:loading":function($event){_vm.refreshLoading=$event}},scopedSlots:_vm._u([{key:"action-column",fn:function(ref){
            var row = ref.row;
return [_c('b-button',{staticClass:"button-purble btn-details",attrs:{"outlined":"","size":"is-small","icon-left":"arrow-right-bold"},on:{"click":function($event){return _vm.leadRegisterDetails(row.plan_id, row.register_date)}}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" ")])]}},{key:"footer",fn:function(data){return [_c('td',{staticClass:"has-text-weight-semibold",attrs:{"colspan":"6"}},[_c('div',{staticClass:"th-wrap"},[_vm._v("Toplam:")])]),_c('td',{staticClass:"has-text-centered has-text-weight-semibold"},[_vm._v(" "+_vm._s(data.data.reduce(function (total, item) { return total + item.total - item.total_trashed; }, 0))+" ")]),_c('td',{staticClass:"has-text-centered has-text-weight-semibold"},[_vm._v(" "+_vm._s(data.data.reduce(function (total, item) { return total + item.cars_count_total; }, 0))+" ")])]}}],null,false,3919955070)},[_c('template',{slot:"header-right"},[_c('b-tooltip',{attrs:{"label":_vm.$t('exportExcel'),"type":"is-dark","position":"is-left"}},[_c('b-button',{staticClass:"filter-button is-hidden-mobile",attrs:{"size":"is-circle","icon-left":"file-excel"},on:{"click":function($event){return _vm.activeTable.excelExport()}}})],1)],1)],2)],1),_c('b-tab-item',{attrs:{"label":"Kayıtlar","icon":"format-list-bulleted-square"}},[_c('smart-table',{ref:"registersTable",attrs:{"data-url":"shuttle-register-list","sort":{
              defaultOrder: 'desc',
              field: 'register_date',
              order: 'asc',
            },"filters":{ with_trashed: 1 },"loading":_vm.refreshLoading},on:{"update:loading":function($event){_vm.refreshLoading=$event}},scopedSlots:_vm._u([{key:"action-column",fn:function(ref){
            var row = ref.row;
return [_c('b-tooltip',{attrs:{"label":row.trashed ? _vm.$t('canceled') : _vm.$t('cancelRequest'),"type":"is-dark","position":"is-right"}},[_c('b-button',{staticClass:"button-green is-danger mr-5 only-icon",attrs:{"outlined":"","size":"is-small","icon-left":"close-circle","disabled":row.trashed},on:{"click":function($event){!row.trashed && _vm.cancelRequest(row.id, false)}}})],1),_c('b-button',{staticClass:"button-purble btn-details",attrs:{"outlined":"","size":"is-small","icon-left":"arrow-right-bold"},on:{"click":function($event){return _vm.registerDetails(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" ")])]}}],null,false,3720995223)},[_c('template',{slot:"header-right"},[_c('b-tooltip',{attrs:{"label":_vm.$t('exportExcel'),"type":"is-dark","position":"is-left"}},[_c('b-button',{staticClass:"filter-button is-hidden-mobile",attrs:{"size":"is-circle","icon-left":"file-excel"},on:{"click":function($event){return _vm.activeTable.excelExport()}}})],1)],1)],2)],1)],1):_c('smart-table',{ref:"registers-table",attrs:{"data-url":"shuttle-register-list","sort":{
          defaultOrder: 'desc',
          field: 'register_date',
          order: 'asc',
        },"filters":{ with_trashed: 1 },"loading":_vm.refreshLoading},on:{"update:loading":function($event){_vm.refreshLoading=$event}},scopedSlots:_vm._u([{key:"action-column",fn:function(ref){
        var row = ref.row;
return [_c('b-tooltip',{attrs:{"label":_vm.$t('cancelRequest'),"type":"is-dark","position":"is-right"}},[_c('b-button',{staticClass:"button-green is-danger mr-5 only-icon",attrs:{"outlined":"","size":"is-small","icon-left":"close-circle"},on:{"click":function($event){return _vm.cancelRequest(row.id, false)}}})],1),_c('b-button',{staticClass:"button-purble btn-details",attrs:{"outlined":"","size":"is-small","icon-left":"arrow-right-bold"},on:{"click":function($event){return _vm.registerDetails(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" ")])]}}])})],1)],1),_c('b-modal',{attrs:{"active":_vm.isDayDetailsModalActive,"has-modal-card":"","trap-focus":"","aria-role":"dialog","aria-modal":""},on:{"update:active":function($event){_vm.isDayDetailsModalActive=$event}}},[_c('ShuttleDayDetails',{attrs:{"shuttleId":_vm.leadShuttleId,"registerDate":_vm.leadRegisterDate}})],1),_c('b-modal',{attrs:{"active":_vm.isDetailsModalActive,"has-modal-card":"","trap-focus":"","aria-role":"dialog","aria-modal":""},on:{"update:active":function($event){_vm.isDetailsModalActive=$event}}},[_c('shuttle-register-details',{attrs:{"id":_vm.registerId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }